import * as React from "react";

import "normalize.css";

import { useEffect, useState } from "react";
import Header from "../components/Header";
import LayoutRoot from "../components/LayoutRoot";
import LayoutMain from "../components/LayoutMain";
import Footer from "../components/Footer";
import ContactIcon from "../components/ContactIcon";
import Container from "../components/Container";
import { AdminApi } from "../api";
import { defaultAPIConfig } from "../config/defaultApiConfig";

const api = new AdminApi(defaultAPIConfig());

const activateAccount = ({ location }) => {
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const params = new URLSearchParams(location.search);
  const token = params.get("token") || "";

  useEffect(() => {
    setIsLoading(true);
    const activateAccountFunction = async () => {
      try {
        const activateAccountResponse = await api.confirmcustomeraccountPost(
          {
            confirmcustomeraccountPostRequest: {
              data: {
                type: "confirmcustomeraccount",
                attributes: {
                  token,
                },
              },
            },
          },
          {
            headers: {
              accept: "application/vnd.api+json",
              "content-type": "application/vnd.api+json",
            },
          }
        );

        if (activateAccountResponse) {
          console.log("activated!", activateAccountResponse);
        }
      } catch (error) {
        const err: any = [];
        err.push({
          text: "Es gab einen Fehler beim Aktivieren des Accounts. Bitte melden Sie sich bei info@rentir.ch",
        });
        setErrors(err);
      }
      setIsLoading(false);
      setErrors([]);
    };

    activateAccountFunction();
  }, []);

  return (
    <LayoutRoot>
      <Header title="Account aktiviert" />
      <LayoutMain>
        <Container>
          <h1>Account aktivieren</h1>
          <p>
            {errors && errors.map((error: any) => <div>{error.text}</div>)}
            {errors.length === 0 && (
              <>
                Sie haben Ihren Account erfolgreich aktiviert. In Kürze erhalten
                Sie eine Mail zum Setzen Ihres Passworts.
              </>
            )}
          </p>
        </Container>
      </LayoutMain>
      <Footer />
      <ContactIcon />
    </LayoutRoot>
  );
};

export default activateAccount;
